import { template as template_eb2735d95c564228b8d0a1ecf6a797e8 } from "@ember/template-compiler";
const FKLabel = template_eb2735d95c564228b8d0a1ecf6a797e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
