import { template as template_6cb8e0ea32484719a7b4f026309d8245 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6cb8e0ea32484719a7b4f026309d8245(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
