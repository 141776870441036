import { template as template_221b058fac1b4031a92ee4edcae1b968 } from "@ember/template-compiler";
const FKText = template_221b058fac1b4031a92ee4edcae1b968(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
